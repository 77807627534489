<template>
  <b-card class="hp-card-5">
    <h5 class="mb-32">Notification</h5>

    <ul class="timeline hp-p2-body">
      <li class="timeline-item">
        <div class="timeline-item-tail"></div>
        <div class="timeline-item-head"></div>
        <div class="timeline-item-content">
          Create a services site 2015-09-01
        </div>
      </li>
      <li class="timeline-item">
        <div class="timeline-item-tail"></div>
        <div class="timeline-item-head"></div>
        <div class="timeline-item-content">
          Create a services site 2015-09-01
        </div>
      </li>
      <li class="timeline-item">
        <div class="timeline-item-tail"></div>
        <div class="timeline-item-head"></div>
        <div class="timeline-item-content">
          Create a services site 2015-09-01
        </div>
      </li>
      <li class="timeline-item">
        <div class="timeline-item-tail"></div>
        <div class="timeline-item-head"></div>
        <div class="timeline-item-content">
          Create a services site 2015-09-01
        </div>
      </li>
      <li class="timeline-item">
        <div class="timeline-item-tail"></div>
        <div class="timeline-item-head"></div>
        <div class="timeline-item-content">
          Create a services site 2015-09-01
        </div>
      </li>
    </ul>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";

export default {
  components: {
    BCard,
  },
};
</script>
